<template>
  <div class="reports-buyers-page">
    <div class="back-wrapper">
      <router-link :to="`/dealer/${dealerId}`" class="back">Назад</router-link>
    </div>

    <CurrentDealer :dealer-id="parseInt(dealerId)" />
    <Buyers />
  </div>
</template>

<script>
import Buyers from "@/components/reports/buyers/Buyers"
import CurrentDealer from "@/components/current-dealer/CurrentDealer"

import { mapGetters } from "vuex"

export default {
  name: "ReportsBuyers",
  components: {
    CurrentDealer,
    Buyers,
  },
  computed: {
    ...mapGetters({
      columns: "dealers/columns",
    }),
    dealerId() {
      return this.$route.params.id
    },
  },
}
</script>

<style lang="scss" scoped>
.reports-buyers-page {
  .dealer-info {
    margin: 20px 0;
  }

  .back-wrapper {
    display: flex;
    justify-content: flex-end;

    width: 100%;
    margin-bottom: 15px;
  }
}
</style>
