<template>
  <a-form class="ant-advanced-search-form" name="advanced_search">
    <div class="filter-and-show">
      <div class="filter-fields">
        <h3>Фильтр</h3>
        <a-form-item label="ТОП-10" class="filter-form-items">
          <a-select class="dealer-select selects" default-value="Все">
            <a-select-option value="all">Все</a-select-option>
            <a-select-option value="top10">ТОП-10</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Период" class="filter-form-items">
          <a-input
            placeholder="Ноябрь 2020"
            value="Ноябрь 2020"
            class="period-select selects"
          ></a-input>
        </a-form-item>
      </div>
    </div>
    <div class="buttons">
      <div>
        <a-button class="leftButtons">Фильтр</a-button>
        <a-button class="leftButtons">Очистить</a-button>
      </div>
    </div>
    <div class="filter-fields"></div>
  </a-form>
</template>

<script>
export default {
  name: "Filters",
}
</script>

<style lang="scss" scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}

#advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}

.search-result-text {
  margin: 20px 0 10px 0;
}

.filter-and-show {
  display: flex;
  flex-direction: row;
}

.filter-fields {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.filter-form-items {
  display: block;
  margin-right: 20px;
  margin-bottom: 10px !important;
}

.leftButtons {
  margin-right: 20px;
}
.rightButtons {
  margin-left: 20px;
}

.dealer-select {
  margin-left: 40px;
}

.period-select {
  margin-left: 31px;
}

.selects {
  width: 200px;
}
</style>
