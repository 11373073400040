<template>
  <div class="buyers">
    <Filters />

    <a-table
      bordered
      :columns="columns"
      :data-source="data"
      :pagination="{ defaultPageSize: 12, hideOnSinglePage: true }"
    ></a-table>

    <Diagrams :list="diagramsList" />
  </div>
</template>

<script>
import Filters from "@/components/reports/buyers/Filters"
import Diagrams from "@/components/diagrams/Diagrams"

import { mapActions, mapGetters } from "vuex"

export default {
  name: "Buyers",
  components: {
    Diagrams,
    Filters,
  },
  data() {
    return {
      diagramsList: [
        {
          link: require("../../../assets/reports/buyers/diagram1.png"),
        },
        {
          link: require("../../../assets/reports/buyers/diagram2.png"),
        },
        {
          link: require("../../../assets/reports/buyers/diagram3.png"),
        },
        {
          link: require("../../../assets/reports/buyers/diagram4.png"),
        },
      ],
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: mapGetters({
    columns: "dealerBuyersReports/columns",
    data: "dealerBuyersReports/data",
  }),
  methods: mapActions({
    fetchData: "dealerBuyersReports/fetchData",
  }),
}
</script>

<style lang="scss">
.buyers {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 16px 4px !important;
  }

  td {
    text-align: center;
  }
}
</style>

<style lang="scss" scoped></style>
